import React from 'react';
import Demand from '../components/feed/demand/Demand';

const Dopyty = () => {
  return (
    <div className="grid grid-cols-3 gap-3 hover:col-span-6">
      <Demand />
      <Demand />
      <Demand />
    </div>
  );
};

export default Dopyty;
