import React from "react";
import "./stepper.css";
import { TiTick } from "react-icons/ti";

const Stepper = ({ stepNumber,steps, isLastStep }) => {
  
const currentStep = stepNumber + 1
  return (
    <div className="flex justify-center">
      {steps?.map((step, i) => (
        <div
          key={i}
          className={`step-item ${currentStep === i + 1 ? "active" : ""} ${
            i + 1 < currentStep  ? "complete" : ""
          }`}
        >
          <div className="step">
            {i + 1 < currentStep  ? <TiTick size={24} /> : i +1 }
          </div>
          <p className="text-gray-500 text-sm">{step.props.stepName}</p>
        </div>
      ))}
    </div>
  );
};

export default Stepper;
