import React from 'react'

const FeedPost = ({post}) => {
  return (
    <div className="flex flex-col gap-4 wi">
    <p>{post?.text}</p>
    {post.imgUrl && (
      <div className="w-full min-h-96 contents">
        <img src={post?.img} alt={post?._id} />
      </div>
    )}
  </div>
  )
}

export default FeedPost