import React from 'react'
import like from "../../assets/images/icons/like.svg";
import comments from "../../assets/images/icons/post.svg";
import share from "../../assets//images/icons/share.svg";
const FeedFooter = ({post}) => {
  return (
    <div className="flex items-center justify-between text-sm my-4">
    <div className="flex gap-8">
      <div className="flex items-center gap-4 bg-slate-50 p-2 rounded-xl">
        <img src={like} alt="" className="cursor-pointer h-4 w-4" />
        <span className=" text-gray-300">|</span>
        <span className=" text-gray-500">
          {post?.likes?.length}
          <span className="hidden md:inline"> Likes</span>
        </span>
      </div>
      <div className="flex items-center gap-4 bg-slate-50 p-2 rounded-xl">
        <img src={comments} alt="" className="cursor-pointer h-4 w-4" />
        <span className=" text-gray-300">|</span>
        <span className=" text-gray-500">
          {post?.comments?.length}
          <span className="hidden md:inline"> Comments</span>
        </span>
      </div>
    </div>
    <div className="">
      <div className="flex items-center gap-4 bg-slate-50 p-2 rounded-xl">
        <img src={share} alt="" className="cursor-pointer h-4 w-4" />
        <span className=" text-gray-300">|</span>
        <span className=" text-gray-500">
          0 <span className="hidden md:inline"> Shares</span>
        </span>
      </div>
    </div>
  </div>

  )
}

export default FeedFooter