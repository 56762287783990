import React from "react";

const MenuItem = ({ onClick, label }) => {
  return (
    <div
      onClick={onClick}
      className="px-4 py-3 hover:bg-mainColor-100 transition font-semibold cursor-pointer"
    >
      {label}
    </div>
  );
};

export default MenuItem;
