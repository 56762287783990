import React from 'react'
import {Link} from "react-router-dom"
const NotFoundPage = () => {
  return ( 
  <div className="flex flex-col gap-4 text-mainColor-400">
    <div className=' text-mainColor-400"'>404 Stránka neexistuje</div>
    <Link to="/">Úvodná stránka</Link>
    </div>
  )
}

export default NotFoundPage