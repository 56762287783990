import React from "react";
import FeedHeader from "./FeedHeader";
import FeedFooter from "./FeedFooter";
import FeedPost from "./FeedPost";

const FeedPosts = ({ post }) => {
 


  return (
    <div className="flex flex-col p-4 my-4 gap-4 bg-white rounded-lg shadow-md">
     <FeedHeader post={post}  />
     <FeedPost post={post}/>
     <FeedFooter post={post}/>
    </div>
  );
};

export default FeedPosts;
