import React from 'react'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import "./RichTextInput.css"


var toolbarOptions = [
  [
    { 'size': ['14px', '16px', '18px'] },
  "bold",
      "italic",
      "underline",
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
      { align: [] },
      { color: ["black", "#fb6c09"] },
  ],
];




const RichTextInput = ({value, onChange }) => {
  return (
    <div>
      <ReactQuill
    value={value}
    onChange={onChange}
    theme={"snow"}
    modules={toolbarOptions}
 /></div>
  )
}

export default RichTextInput