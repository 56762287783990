import React from 'react'
import SearchBar from '../components/search/SearchBar'

const Ponuky = () => {
  return (
    
    <div className="">
    <SearchBar/>
    <div>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Reprehenderit eligendi voluptatum suscipit sequi sunt pariatur aspernatur sapiente ipsam, sit ab, maiores necessitatibus. Dolor corporis sit neque error earum. Possimus, illum.</div>
    </div>
  )
}

export default Ponuky