import Navbar from "../components/navbar/Navbar";
import { Outlet } from "react-router-dom";
import Modal from "../components/modals/Modal";
import useHeader from "../hooks/UseHeader";
import { Toaster } from "react-hot-toast";
import { useQuery } from "@tanstack/react-query";
import apiRequest from "../lib/ApiRequest";

const Layout = () => {
  const { modalOpen, toggleModal } = useHeader();

  const { data: authUser } = useQuery({
    queryKey: ["authUser"],
    queryFn: async () => {
      try {
        const res = await apiRequest.get("auth/me");
        const data = res.data;
        if (res.status !== 200) {
          throw new Error("Failed to fetch authenticated user.");
        }
       return data;
      } catch (error) {
        throw error; // Let useQuery handle the error
      }
    },
  });

  return (
    <>
      {!authUser && <Modal modalOpen={modalOpen} toggleModal={toggleModal} />}
      <div className="shadow-slate-200">
        <Toaster position="bottom-left" reverseOrder={false} />
        <Navbar />
      </div>
      <div className="flex flex-col w-full h-full bg-gradient-to-t from-mainColor-100 to-gray-50">
        {/* <div className="h-[calc(100vh-6rem)]"> */}
        <Outlet />
        {/* </div> */}
      </div>
    </>
  );
};

export default Layout;
