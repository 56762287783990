import {  useState } from "react";
import { GoPencil } from "react-icons/go";
import ModalProfileImg from "../modals/ModalProfileImg";
import {  useQuery } from "@tanstack/react-query";
import avatar from "../../assets/images/icons/avatar.png";

const ProfileImage = () => {
  const { data: user } = useQuery({ queryKey: ["user"] });
  const { data: authUser } = useQuery({ queryKey: ["authUser"] });
  const [modalOpen, setModalOpen] = useState(false);
 
  const IsMyProfile = authUser?._id === user?._id;

  return (
    <div className="flex flex-col items-center">
      <div className="relative mx-3 top-[-50px]  ">
        <img
          src={user?.profileImg || avatar}
          alt="Profile"
          className="w-[150px] h-[150px] rounded-full border-2 border-mainColor-500"
        />

        {IsMyProfile && (
          <button
            className="absolute -bottom-3 left-0 right-0 m-auto w-fit p-[1rem] rounded-full bg-white hover:bg-mainColor-500 border-2 border-mainColor-500"
            title="Change photo"
            onClick={() => setModalOpen(true)}
          >
            <GoPencil />
          </button>
        )}
      </div>
      {modalOpen && <ModalProfileImg
          closeModal={() => setModalOpen(false)} 
        />}
    </div>
  );
};
export default ProfileImage;
