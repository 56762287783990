const PostSkeleton = () => {
	return (
		<div className='flex flex-col p-4 my-4 gap-4 bg-white rounded-lg shadow-md'>
			<div className='flex gap-4 items-center'>
				<div className='skeleton w-16 h-16 rounded-full shrink-0'></div>
				<div className='flex flex-col gap-2'>
					<div className='skeleton h-4 w-12 rounded-full'></div>
					
				</div>
			</div>
			<div className='skeleton h-40 w-full'></div>
		</div>
	);
};
export default PostSkeleton;