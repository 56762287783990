import React, { useEffect } from "react";
import { useParams, useOutletContext } from "react-router-dom"; 
import { useQuery } from "@tanstack/react-query";
import apiRequest from "../lib/ApiRequest";
import Post from "../components/feed/posts/Post";
import AddPost from "../components/feed/AddPost";
import FeedProfile from "../components/feedPost/FeedProfile";

const ProfilePage = () => {
  const { profileId } = useParams(); // Assuming you use profileId from the URL
  const { data: authUser } = useQuery({ queryKey: ["authUser"] });
  const { selectedTab } = useOutletContext(); 

  const { data: userPost, refetch } = useQuery({
    queryKey: ["userPost"],
    queryFn: async () => {
      try {
        const res = await apiRequest.get(`posts/user/${profileId}`);
        if (res.status !== 200) {
          throw new Error("Failed to fetch user posts.");
        }

        const data = res.data;
        if (data.error) return null;
        if (!data || typeof data !== "object") {
          throw new Error("Invalid response data format.");
        }

        return data;
      } catch (error) {
        throw error; // Let useQuery handle the error
      }
    },
  });

  useEffect(() => {
		refetch();
	}, [profileId, refetch]);

   const IsMyProfile = authUser?._id === profileId;

  return (
    <div className="flex flex-col gap-6">

      {selectedTab === "posts" && (
        <>
        
          {IsMyProfile &&(
            <AddPost />
            
          )}          
          <div className="mt-8 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
            {userPost ? (
              userPost.map((post) => 
              
                <div className="flex flex-col p-4 my-4 gap-4 bg-white rounded-lg shadow-md">
              <div className="w-full min-h-96 contents">
              <img src={post?.img} alt={post?._id} />
            </div> 
            </div>
              )
            ) : (
              <p>No posts available.</p> // Fallback message
            )}
          </div>
        </>
      )}
      {selectedTab === "calendar" && <div>Calendar Content</div>}
      {selectedTab === "ratings" && <div>Ratings Content</div>}
    </div>
  );
};

export default ProfilePage;
