import React from 'react'
import { Link } from 'react-router-dom'
import { FaTrash } from "react-icons/fa";
import avatar from "../../assets/images/icons/avatar.png";
import { useQuery } from '@tanstack/react-query';
import Button from '../Button';
const FeedHeader = ({post}) => {
  
    const { data: authUser } = useQuery({
        queryKey: ["authUser"],
      });
 const postOwner = post?.user;

    const handleDeletePost = () => {
        // deletePost();
      };

      const displayName = post?.user?.isSupplier ? (
        <>
          <span className="font-bold text-mainColor-500">
            {postOwner?.company?.[0]?.companyName}
          </span>
        </>
      ) : (
        <>
          <span>{`${postOwner?.firstName} ${postOwner?.lastName}`}</span>
        </>
      );

      const IsMyPost = authUser?._id === postOwner?._id;

    return (
    <div> {/* HeaderPost */}
    <div className="flex items-center justify-between">
      <Link
        to={`/profile/${postOwner?._id}`}
        className="flex items-center gap-4"
      >
        <div className="rounded-full h-8 w-8 overflow-hidden ring-2 ring-mainColor ">
          <img
            src={postOwner?.profileImg || avatar}
            alt="User Avatar"
            className="h-8 w-8 rounded-full object-cover"
          />
        </div>
        <h2 className="font-normal md:font-bold">
          {displayName}
          {/* firstName */}
        </h2>
      </Link>
      
      <span>
        {IsMyPost ? (
          <FaTrash
            className="cursor-pointer text-mainColor-500 hover:text-red-500 "
            onClick={handleDeletePost}
          />
        ):(
<Button label={"follow"}/>
        )}
      </span>
    </div>
</div>
  )
}

export default FeedHeader