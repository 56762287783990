import React from "react";

import RightMenu from "../components/rightMenu/RightMenu";
import LeftMenu from "../components/leftMenu/LeftMenu";
import Feeds from "../components/feed/Feeds";
import Stories from "../components/feed/Stories";

const HomePage = () => {
  return (
    <div className="flex gap-6 pt-6">
      {/* Left */}
      <div className="hidden xl:block w-[20%]">
        <LeftMenu type="home" />
      </div>
      <div className="w-full lg:w-[60%]">
        <div className="flex flex-col gap-6">
          <Stories />
          <Feeds />
        </div>
      </div>
      {/* Right */}
      <div className="hidden lg:block w-[40%]">
        <RightMenu />
      </div>
    </div>
  );
};

export default HomePage;
