import React, { useState } from "react";
import { GrSearch } from "react-icons/gr";

const Search = () => {
  const [profession, setProfession] = useState("");

  const handleSearch = (e) => {
    e.preventDefault();
    // Handle the search logic here
    console.log({ profession });
  };

  return (
    <form
      onSubmit={handleSearch}
      className="max-w-4xl mx-auto p-4 bg-white shadow-lg rounded-full flex items-center space-x-2"
    >
      {/* Profession input field */}
      <div className="w-full">
        <label
          htmlFor="profession"
          className="block text-sm font-medium text-gray-700"
        >
          Koho potrebuješ?
        </label>
        <div className="flex items-center">
          <input
            id="profession"
            name="profession"
            type="text"
            value={profession}
            onChange={(e) => setProfession(e.target.value)}
            placeholder="Profesia"
            className="mt-1 block w-full rounded-full border-gray-300 shadow-sm focus:border-mainColor-500 focus:ring-mainColor-500 sm:text-sm"
          />
          {/* Search button on the right for small screens */}
          <button
            type="submit"
            className="right-2 top-1/2 flex justify-center items-center p-2 rounded-full text-white bg-mainColor-500 hover:bg-mainColor-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-mainColor-500"
          >
            <GrSearch />
          </button>
        </div>
      </div>
    </form>
  );
};

export default Search;
