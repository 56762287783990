import React from "react";

const Stories = () => {
  return (
    <div className="p-4 bg-white rounded-lg shadow-md overflow-scroll text-xs scrollbar-hide">
      <div className="flex gap-8 w-max">
        <div className="flex flex-col items-center gap-2 cursor-pointer">
          <img
            src=""
            alt=""
            className="w-20 h-20 rounded-full ring-2 ring-mainColor"
          />
          <span className="font-medium "> TaProfi s.r.o.</span>
        </div>
        <div className="flex flex-col items-center gap-2 cursor-pointer">
          <img
            src=""
            alt=""
            className="w-20 h-20 rounded-full ring-2 ring-mainColor"
          />
          <span className="font-medium "> TaProfi s.r.o.</span>
        </div>{" "}
        <div className="flex flex-col items-center gap-2 cursor-pointer">
          <img
            src=""
            alt=""
            className="w-20 h-20 rounded-full ring-2 ring-mainColor"
          />
          <span className="font-medium "> TaProfi s.r.o.</span>
        </div>

        <div className="flex flex-col items-center gap-2 cursor-pointer">
          <img
            src=""
            alt=""
            className="w-20 h-20 rounded-full ring-2 ring-mainColor"
          />
          <span className="font-medium "> TaProfi s.r.o.</span>
        </div>
        <div className="flex flex-col items-center gap-2 cursor-pointer">
          <img
            src=""
            alt=""
            className="w-20 h-20 rounded-full ring-2 ring-mainColor"
          />
          <span className="font-medium "> TaProfi s.r.o.</span>
        </div>
        <div className="flex flex-col items-center gap-2 cursor-pointer">
          <img
            src=""
            alt=""
            className="w-20 h-20 rounded-full ring-2 ring-mainColor"
          />
          <span className="font-medium "> TaProfi s.r.o.</span>
        </div>
        <div className="flex flex-col items-center gap-2 cursor-pointer">
          <img
            src=""
            alt=""
            className="w-20 h-20 rounded-full ring-2 ring-mainColor"
          />
          <span className="font-medium "> TaProfi s.r.o.</span>
        </div>
        <div className="flex flex-col items-center gap-2 cursor-pointer">
          <img
            src=""
            alt=""
            className="w-20 h-20 rounded-full ring-2 ring-mainColor"
          />
          <span className="font-medium "> TaProfi s.r.o.</span>
        </div>
        <div className="flex flex-col items-center gap-2 cursor-pointer">
          <img
            src=""
            alt=""
            className="w-20 h-20 rounded-full ring-2 ring-mainColor"
          />
          <span className="font-medium "> TaProfi s.r.o.</span>
        </div>
        <div className="flex flex-col items-center gap-2 cursor-pointer">
          <img
            src=""
            alt=""
            className="w-20 h-20 rounded-full ring-2 ring-mainColor"
          />
          <span className="font-medium "> TaProfi s.r.o.</span>
        </div>
        <div className="flex flex-col items-center gap-2 cursor-pointer">
          <img
            src=""
            alt=""
            className="w-20 h-20 rounded-full ring-2 ring-mainColor"
          />
          <span className="font-medium "> TaProfi s.r.o.</span>
        </div>
        <div className="flex flex-col items-center gap-2 cursor-pointer">
          <img
            src=""
            alt=""
            className="w-20 h-20 rounded-full ring-2 ring-mainColor"
          />
          <span className="font-medium "> TaProfi s.r.o.</span>
        </div>
        <div className="flex flex-col items-center gap-2 cursor-pointer">
          <img
            src=""
            alt=""
            className="w-20 h-20 rounded-full ring-2 ring-mainColor"
          />
          <span className="font-medium "> TaProfi s.r.o.</span>
        </div>
        <div className="flex flex-col items-center gap-2 cursor-pointer">
          <img
            src=""
            alt=""
            className="w-20 h-20 rounded-full ring-2 ring-mainColor"
          />
          <span className="font-medium "> TaProfi s.r.o.</span>
        </div>
      </div>
    </div>
    
  );
};

export default Stories;
