import React, { useState } from 'react'
import { GrSearch } from "react-icons/gr";
const SearchBar = () => {
  const [location, setLocation] = useState('');
  const [checkIn, setCheckIn] = useState('');
  const [checkOut, setCheckOut] = useState('');
  const [profession, setProfession] = useState('');

  const handleSearch = (e) => {
    e.preventDefault();
    // Handle the search logic here
    console.log({ location, checkIn, checkOut, profession });
  };

  return (
    <form onSubmit={handleSearch} className="max-w-4xl mx-auto p-4 bg-white shadow-lg rounded-full flex flex-col md:flex-row items-center space-y-2 md:space-y-0 md:space-x-2">
      <div className="flex-grow border-gray-300 shadow-sm focus:border-mainColor-500 focus:ring-mainColor-500 sm:text-sm">
        <label htmlFor="location" className="block text-sm font-medium text-gray-700">Kde</label>
        <input
          id="location"
          name="location"
          type="text"
          value={location}
          onChange={(e) => setLocation(e.target.value)}
          placeholder="Zadaj okres alebo kraj"
          className="mt-1 block w-full border-none rounded-md focus:border-mainColor-500 focus:ring-mainColor-500 sm:text-sm"
        />
      </div>

      <div className="flex-grow">
        <label htmlFor="checkIn" className="block text-sm font-medium text-gray-700">Odkedy</label>
        <input
          id="checkIn"
          name="checkIn"
          type="date"
          value={checkIn}
          onChange={(e) => setCheckIn(e.target.value)}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-mainColor-500 focus:ring-mainColor-500 sm:text-sm"
        />
      </div>

      <div className="flex-grow">
        <label htmlFor="checkOut" className="block text-sm font-medium text-gray-700">Dokedy</label>
        <input
          id="checkOut"
          name="checkOut"
          type="date"
          value={checkOut}
          onChange={(e) => setCheckOut(e.target.value)}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-mainColor-500 focus:ring-mainColor-500 sm:text-sm"
        />
      </div>

      <div className="flex-grow">
        <label htmlFor="profession" className="block text-sm font-medium text-gray-700">Koho potrebuješ?</label>
        <input
          id="profession"
          name="profession"
          type="text"
          value={profession}
          onChange={(e) => setProfession(e.target.value)}
          placeholder="Profesia"
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-mainColor-500 focus:ring-mainColor-500 sm:text-sm"
        />
      </div>

      <button
        type="submit"
        className="mt-1 w-full md:w-auto flex justify-center py-2 px-4 border border-transparent rounded-full shadow-sm text-sm font-medium bg-mainColor hover:bg-mainColor-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-mainColor-500"
      >
        <GrSearch />
      </button>
    </form>
  )
}

export default SearchBar;