import { useRef, useState } from "react";
import ReactCrop, { centerCrop, makeAspectCrop, convertToPixelCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { BsChevronCompactLeft, BsChevronCompactRight } from "react-icons/bs";
import { RxDotFilled } from "react-icons/rx";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";
import apiRequest from "../lib/ApiRequest";

const ASPECT_RATIO = 16 / 9; // 16:9 aspect ratio
const MAX_IMAGES = 5;

const MultiImageCropper = ({ closeModal }) => {
  const imgRefs = useRef([]); // References for each image element
  const canvasRefs = useRef([]); // References for each canvas
  const [imgSrcs, setImgSrcs] = useState([]); // Array of image sources
  const [crops, setCrops] = useState([]); // Crop configurations for each image
  const [currentIndex, setCurrentIndex] = useState(0); // Current image in carousel
  const [croppedPreviews, setCroppedPreviews] = useState([]); // URLs of cropped previews
  const queryClient = useQueryClient();

  // Mutation to upload cropped images
  const { mutate: uploadImagesMutation } = useMutation({
    mutationFn: async ({ files }) => {
      const formData = new FormData();

      // Append up to 5 files to the form data
      for (let i = 0; i < files.length && i < 5; i++) {
        formData.append("coverImg", files[i]); // Ensure this matches your backend field name
      }

      const res = await apiRequest.post("/users/updateCoverImg", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return res.data;
    },
    onSuccess: (data) => {
      toast.success(data.message);
      queryClient.invalidateQueries({ queryKey: ["user"] });
      queryClient.invalidateQueries({ queryKey: ["authUser"] });
      closeModal();
    },
    onError: (error) => {
      const message = error.response?.data?.message || "Something went wrong!";
      toast.error(message);
    },
  });

  // Handle file selection
  const handleSelectFiles = (e) => {
    const selectedFiles = Array.from(e.target.files).slice(0, MAX_IMAGES);
    const fileReaders = selectedFiles.map((file) => {
      const reader = new FileReader();
      return new Promise((resolve) => {
        reader.onload = () => resolve(reader.result);
        reader.readAsDataURL(file);
      });
    });

    Promise.all(fileReaders).then((urls) => {
      setImgSrcs(urls);
      setCrops(urls.map(() => null));
      setCroppedPreviews([]);
    });
  };

  // Initial crop setup for each image on load
  const handleImageLoad = (index) => (e) => {
    const { width, height } = e.currentTarget;
    const crop = makeAspectCrop(
      { unit: "%", width: 50 }, // Start with a 50% width crop
      ASPECT_RATIO,
      width,
      height
    );
    const centeredCrop = centerCrop(crop, width, height);
    setCrops((prev) => {
      const newCrops = [...prev];
      newCrops[index] = centeredCrop;
      return newCrops;
    });
  };

  // Generate cropped preview
  const generateCroppedPreview = () => {
    const imgRef = imgRefs.current[currentIndex];
    const canvas = document.createElement("canvas");
    const crop = crops[currentIndex];

    if (imgRef && crop?.width && crop?.height) {
      const scaleX = imgRef.naturalWidth / imgRef.width;
      const scaleY = imgRef.naturalHeight / imgRef.height;

      canvas.width = crop.width * scaleX;
      canvas.height = crop.height * scaleY;
      const ctx = canvas.getContext("2d");

      ctx.drawImage(
        imgRef,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        canvas.width,
        canvas.height
      );

      // Create preview URL and store it
      canvas.toBlob((blob) => {
        if (blob) {
          const previewUrl = URL.createObjectURL(blob);
          setCroppedPreviews((prev) => {
            const newPreviews = [...prev];
            newPreviews[currentIndex] = previewUrl;
            return newPreviews;
          });
        }
      }, "image/jpeg");
    }
  };

  // Convert cropped previews to files and upload
  const handleUpload = () => {
    const files = croppedPreviews.map((previewUrl, index) => {
      return fetch(previewUrl)
        .then((res) => res.blob())
        .then((blob) => new File([blob], `croppedImage${index + 1}.jpg`, { type: "image/jpeg" }));
    });

    // Wait for all files to be created, then trigger the upload mutation
    Promise.all(files).then((fileObjects) => {
      uploadImagesMutation({ files: fileObjects });
    });
  };

  // Navigate carousel
  const prevSlide = () => setCurrentIndex((prev) => (prev === 0 ? imgSrcs.length - 1 : prev - 1));
  const nextSlide = () => setCurrentIndex((prev) => (prev === imgSrcs.length - 1 ? 0 : prev + 1));

  // Handle crop changes
  const handleCropChange = (crop) => {
    setCrops((prev) => {
      const newCrops = [...prev];
      newCrops[currentIndex] = crop;
      return newCrops;
    });
  };

  return (
  <>
  <div className="w-full max-w-2xl mx-auto">
      {/* File input */}
      <label className="block mb-3 w-fit">
        <span className="sr-only">Choose photos</span>
        <input
          type="file"
          accept="image/*"
          multiple
          onChange={handleSelectFiles}
          className="block w-full text-sm text-slate-500 file:mr-4 file:py-1 file:px-2 file:rounded-full file:border-0 file:text-xs file:bg-mainColor-500 file:text-white hover:file:bg-mainColor-600"
        />
      </label>
      <div className="flex justify-evenly">
    <button
            onClick={generateCroppedPreview}
           className="text-white font-mono text-xs py-2 px-4 rounded-2xl mt-4 bg-mainColor-500 hover:bg-mainColor-600"
          >
             {`Orezať a Zobraziť slide ${currentIndex + 1}`}
          </button>
    
          {/* Upload Button */}
          <button
            onClick={handleUpload}
            className="text-white font-mono text-xs py-2 px-4 rounded-2xl mt-4 bg-mainColor-500 hover:bg-mainColor-600"
            >
            Uložiť
          </button>
    </div>

      {/* Image Carousel with Crop */}
      <div className="relative w-full h-96 bg-gray-200 rounded-lg">
        {imgSrcs.length > 0 ? (
          <ReactCrop
            crop={crops[currentIndex]}
            onChange={handleCropChange}
            aspect={ASPECT_RATIO}
          >
            <img
              ref={(el) => (imgRefs.current[currentIndex] = el)}
              src={imgSrcs[currentIndex]}
              onLoad={handleImageLoad(currentIndex)}
              className="w-full h-full object-cover rounded-lg"
              alt={`Slide ${currentIndex + 1}`}
            />
          </ReactCrop>
        ) : (
          <div className="w-full h-full flex items-center justify-center text-mainColor-500 ">
            No Images Available
          </div>
        )}

        {/* Left Arrow */}
        {imgSrcs.length  >= 2 && (
        <div
          className="absolute top-1/2 -left-5 transform -translate-y-1/2  rounded-full p-2 bg-black/20 cursor-pointer"
          onClick={prevSlide}
        >
          <BsChevronCompactLeft size={24} className="text-mainColor-500"  />
        </div>
)}
        {/* Right Arrow */}
       {imgSrcs.length >= 2 && (
  <div
    className="absolute top-1/2 -right-5 transform -translate-y-1/2 rounded-full p-2 bg-black/20 cursor-pointer"
    onClick={nextSlide}
  >
    <BsChevronCompactRight size={24} className="text-mainColor-500" />
  </div>
)}

       

        {/* Dots Navigation */}
        <div className="flex justify-center mt-4">
          {imgSrcs.map((_, index) => (
            <RxDotFilled
              key={index}
              onClick={() => setCurrentIndex(index)}
              className={`cursor-pointer ${index === currentIndex ? "text-black" : "text-gray-300"}`}
            />
          ))}
        </div>
      </div>

      {/* Crop and Preview Button */}


      {/* Cropped Previews */}
      <div className="mt-4 grid grid-cols-2 gap-4">
        {croppedPreviews.map((previewUrl, index) => (
          <img
            key={index}
            src={previewUrl}
            alt={`Cropped Preview ${index + 1}`}
            className="w-full h-32 object-cover rounded"
          />
        ))}
      </div>
    </div>
    
    </>
  );
};

export default MultiImageCropper;
