import React, { useEffect, useState } from "react";
import { Outlet, useParams } from "react-router-dom";
import LeftMenu from "../components/leftMenu/LeftMenu";
import RightMenu from "../components/rightMenu/RightMenu";
import ProfileHeader from "../components/profile/ProfileHeader";
import apiRequest from "../lib/ApiRequest";
import { useQuery } from "@tanstack/react-query";

const ProfilePage = () => {
  const { profileId } = useParams();
  const [selectedTab, setSelectedTab] = useState("posts");

  // Ensure profileId is available before making the query
  const {
    data: user,
    isError,
    isLoading,
    refetch,
    isRefetching,
  } = useQuery({
    queryKey: ["user"], // Include profileId in queryKey
    queryFn: async () => {
      try {
        if (!profileId) {
          throw new Error("Profile ID is missing");
        }
        const res = await apiRequest.get(`users/profile/${profileId}`);
        if (res.status !== 200) {
          throw new Error("Failed to fetch user profile.");
        }

        const data = res.data;
        if (!data || typeof data !== "object") {
          throw new Error("Invalid response data format.");
        }

        return data;
      } catch (error) {
        throw error;
      }
    },
    enabled: !!profileId, // Prevents query from running if profileId is missing
  });


  useEffect(() => {
		refetch();
	}, [profileId, refetch]);

  // Handle loading and error states
  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return (
      <div>User profile not found. Please check the ID or try again later.</div>
    );
  }

  const handleTabChange = (tab) => {
    setSelectedTab(tab); // Change the selected tab
  };

  return (
    <>
      <div className="bg-white">
        <ProfileHeader user={user} selectedTab={selectedTab} onTabChange={handleTabChange} />
      </div>
      <div className="flex gap-6 pt-6">
        {/* LeftMenu is only visible on xl screens */}
        <div className="hidden xl:block w-[20%]">
          <LeftMenu type="home" />
        </div>

        {/* Main content area */}
        <div className="w-full xl:w-[50%]">
          <Outlet context={{ selectedTab, onTabChange: handleTabChange }}/>
        </div>

        {/* RightMenu is only visible on lg screens */}
        <div className="hidden lg:block w-[30%]">
          <RightMenu />
        </div>
      </div>
    </>
  );
};

export default ProfilePage;
