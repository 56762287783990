import React from "react";
import logoAppka from "../../assets/images/logoAppka.png";
import logoNadpisy from "../../assets/images/logoNadpisy.png";
import UserMenu from "./components/UserMenu";
import Search from "../search/Search";

import { NavLink } from "react-router-dom";
import useHeader from "../../hooks/UseHeader";

const Navbar = () => {
  const { modalOpen, toggleModal } = useHeader;

  return (
    <nav className="w-full border-b bg-white border-mainColor-400 h-48 flex flex-col justify-center shadow-sm">
      <div className="flex flex-row items-center justify-between gap-3 md:gap-0 px-4 sm:px-8 md:px-12 lg:px-12 xl:px-24 ">
        {/* LEFT */}
        <NavLink className="flex items-center w-[30%] cursor-pointer" href="/">
          <img src={logoAppka} alt="logo Taprofi" className="h-[40px] " />
          <img
            src={logoNadpisy}
            alt="logo Taprofi"
            className="hidden sm:block h-[35px] "
          />
        </NavLink>

        {/* CENTER */}
        <div className="hidden md:flex w-[40%] justify-evenly text-mainColor ">
          <NavLink
            to="ponuky"
            className={({ isActive }) => {
              return isActive
                ? "text-mainColor-500 font-semibold"
                : "text-mainColor-300 p-4 md:py-1 md:px-2 rounded-full hover:bg-slate-100";
            }}
          >
            {" "}
            Ponuky
          </NavLink>
          <NavLink
            to="dopyty"
            className={({ isActive }) => {
              return isActive
                ? "text-mainColor-500 font-semibold"
                : "text-mainColor-300 p-4 md:py-1 md:px-2 rounded-full hover:bg-slate-100";
            }}
          >
            {" "}
            Dopyty
          </NavLink>

          <NavLink
            to="learning"
            className={({ isActive }) => {
              return isActive
                ? "text-mainColor-500 font-semibold"
                : "text-mainColor-300 p-4 md:py-1 md:px-2 rounded-full hover:bg-slate-100";
            }}
          >
            {" "}
            Školenia
          </NavLink>
        </div>
        {/* RIGHT */}
        <div className="w-[30%] flex items-center gap-4 xl:gap-8 justify-end">
          <UserMenu toggleModal={toggleModal} modalOpen={modalOpen} />
        </div>
      </div>
      {/* <div className=""><SearchBar/></div> */}
      <div className="">
        <Search />
      </div>
    </nav>
  );
};

export default Navbar;
