import { useRef, useState } from "react";
import ReactCrop, { centerCrop, convertToPixelCrop, makeAspectCrop } from "react-image-crop";
import setCanvasPreview from "../setCanvasPreview";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";
import apiRequest from "../lib/ApiRequest";

const ASPECT_RATIO = 1;
const MIN_DIMENSION = 150;

const ImageCropper = ({ closeModal }) => {
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [imgSrc, setImgSrc] = useState("");
  const [crop, setCrop] = useState();
  const [error, setError] = useState("");
  const queryClient = useQueryClient();

  // Mutation to upload the cropped image
  const { mutate: uploadImage } = useMutation({
    mutationFn: async (file) => {
      const formData = new FormData();
      formData.append("profileImg", file);

      const res = await apiRequest.post("/users/updateProfileImg", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return res.data;
    },
    onSuccess: (data) => {
      toast.success(data.message);
      queryClient.invalidateQueries({ queryKey: ["user"] });
      queryClient.invalidateQueries({ queryKey: ["authUser"] });
      closeModal(); // Close modal on success
    },
    onError: (error) => {
      const message = error.response?.data?.message || "Something went wrong!";
      toast.error(message);
    },
  });

  const onSelectFile = (e) => {
    const file = e.target.files?.[0];
    if (!file) return;

    const reader = new FileReader();
    reader.addEventListener("load", () => {
      setImgSrc(reader.result.toString());
    });
    reader.readAsDataURL(file);
  };

  const onImageLoad = (e) => {
    const { width, height } = e.currentTarget;
    const crop = makeAspectCrop(
      { unit: "%", width: (MIN_DIMENSION / width) * 100 },
      ASPECT_RATIO,
      width,
      height
    );
    setCrop(centerCrop(crop, width, height));
  };

  const handleCropAndUpload = () => {
    setCanvasPreview(
      imgRef.current,
      previewCanvasRef.current,
      convertToPixelCrop(crop, imgRef.current.width, imgRef.current.height)
    );
    previewCanvasRef.current.toBlob(
      (blob) => {
        if (blob) {
          const file = new File([blob], "profile.jpg", { type: "image/jpeg" });
          uploadImage(file); // Upload the file directly from here
        }
      },
      "image/jpeg",
      1 // Quality parameter
    );
  };

  return (
    <>
      <label className="block mb-3 w-fit">
        <span className="sr-only">Choose profile photo</span>
        <input
          type="file"
          accept="image/*"
          onChange={onSelectFile}
          className="block w-full text-sm text-slate-500 file:mr-4 file:py-1 file:px-2 file:rounded-full file:border-0 file:text-xs file:bg-mainColor-500 file:text-white hover:file:bg-mainColor-600"
        />
      </label>
      {error && <p className="text-red-400 text-xs">{error}</p>}
      {imgSrc && (
        <div className="flex flex-col items-center">
          <ReactCrop
            crop={crop}
            onChange={(newCrop) => setCrop(newCrop)}
            circularCrop
            keepSelection
            aspect={ASPECT_RATIO}
            minWidth={MIN_DIMENSION}
          >
            <img ref={imgRef} src={imgSrc} alt="Upload" onLoad={onImageLoad} />
          </ReactCrop>
          <button
            className="text-white font-mono text-xs py-2 px-4 rounded-2xl mt-4 bg-mainColor-500 hover:bg-mainColor-600"
            onClick={handleCropAndUpload}
          >
            Orezať a Uložiť
          </button>
    
        </div>
      )}
      <canvas ref={previewCanvasRef} style={{ display: "none" }} />
    </>
  );
};

export default ImageCropper;
