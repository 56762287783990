import React from 'react';

const Demand = () => {
  return (
    <div className="mx-auto bg-white rounded-lg shadow-lg p-4">
      {/* Image Placeholder */}
      <div className="bg-gray-300 rounded-lg w-full h-48 mb-4"></div>

      {/* Bottom Content */}
      <div className="flex items-center justify-between">
        {/* Logo and Description */}
        <div className="flex items-center">
          {/* Logo Placeholder */}
          <div className="border-2 border-orange-500 rounded-full w-16 h-16 flex items-center justify-center text-center mr-4">
            <div className="text-sm">
              <div>Logo</div>
              <div>firmy</div>
            </div>
          </div>

          {/* Firma and Popis */}
          <div>
            <div className="text-lg font-bold">Nazov firma</div>
            <div className="text-sm text-gray-500">popis</div>
          </div>
        </div>

        {/* Location Info */}
        <div className="text-right text-sm font-semibold text-gray-700">
          kraj/mesto
        </div>
      </div>
    </div>
  );
};

export default Demand;