import { MdOutlineAddAPhoto, MdOutlineEmojiEmotions } from "react-icons/md";
import { useRef, useState } from "react";
import { IoCloseSharp } from "react-icons/io5";
import apiRequest from "../../lib/ApiRequest";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import avatar from "../../assets/images/icons/avatar.png";
import { toast } from "react-hot-toast"; // Make sure to import toast if it's being used
import Button from "../Button";

const AddPost = () => {
  const [text, setText] = useState("");
  const [img, setImg] = useState(null);
  const [file, setFile] = useState(null);
  const imgRef = useRef(null);

  const { data: authUser } = useQuery({ queryKey: ["authUser"] });
  const queryClient = useQueryClient();

  const {
    mutate: createPost,
    isPending,
    isError,
    error,
  } = useMutation({
    mutationFn: async ({ text, img }) => {
      const formData = new FormData();
      formData.append("postsImage", img); // Pass the raw file, not the data URL
      formData.append("text", text);

      try {
        const res = await apiRequest.post("/posts/create", formData, {
          headers: {
            "Content-Type": "multipart/form-data", // Ensure correct headers for file upload
          },
        });

        return res.data; // Assuming the response has the data you need
      } catch (error) {
        throw new Error(error.response?.data?.error || error.message);
      }
    },

    onSuccess: () => {
      setText("");
      setImg(null);
      setFile(null);
      toast.success("Post created successfully");
      queryClient.invalidateQueries({ queryKey: ["posts"] });
      queryClient.invalidateQueries({ queryKey: ["userPost"] });
    },

    onError: (error) => {
      toast.error(error.message);
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!text && !file) {
      toast.error("Please provide either text or an image for the post.");
      return;
    }
    createPost({ text, img: file });
  };

  const handleImgChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = () => {
        setImg(reader.result); // For previewing the image
        setFile(selectedFile); // Save the actual file to be sent to the backend
      };
      reader.readAsDataURL(selectedFile);
    }
  };

  const displayName = authUser?.isSupplier ? (
    <>
      <span className="font-bold text-mainColor-500">
        {authUser?.company?.[0]?.companyName}
      </span>
    </>
  ) : (
    <>
      <span>{`${authUser?.firstName} ${authUser?.lastName}`}</span>
    </>
  );

  return (
    <div className="shadow-md bg-white rounded-lg px-6 py-4">
      <div className="flex items-center justify-start gap-4">
        <div className="rounded-full h-12 w-12 overflow-hidden ring-2 ring-mainColor ">
          <img
            className="h-12 w-12 rounded-full object-cover"
            src={authUser?.profileImg || avatar}
            alt="profile image"
          />
        </div>
        <div>{displayName}</div>
      </div>
      <form className="flex flex-col gap-2 w-full" onSubmit={handleSubmit}>
        <textarea
          className="textarea w-full p-0 text-lg resize-none border-none  border-gray-800 focus:outline-none"
          placeholder="Pridat prispevok..."
          value={text}
          onChange={(e) => setText(e.target.value)}
        />
        {img && (
          <div className="relative w-72 mx-auto">
            <IoCloseSharp
              className="absolute top-0 right-0 text-mainColor rounded-full w-5 h-5 cursor-pointer"
              onClick={() => {
                setImg(null);
                setFile(null);
                imgRef.current.value = null;
              }}
            />
            <img
              src={img}
              className="w-full mx-auto h-72 object-contain rounded"
              alt="Post Preview"
            />
          </div>
        )}

        <div className="flex justify-between border-t py-2 border-t-mainColor-500">
          <div className="flex gap-1 items-center">
            <MdOutlineAddAPhoto
              className="fill-primary w-6 h-6 text-mainColor cursor-pointer"
              onClick={() => imgRef.current.click()}
            />
            <MdOutlineEmojiEmotions className="fill-primary w-5 h-5 cursor-pointer text-mainColor" />
          </div>
          <input
            type="file"
            accept="image/*"
            hidden
            ref={imgRef}
            onChange={handleImgChange}
          />
          <Button
            type="submit"
            small
            label={isPending ? "Pridávam..." : "Pridať"}
          />
        </div>
        {isError && <div className="text-red-500">{error.message}</div>}
      </form>
    </div>
  );
};

export default AddPost;
