import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import React, { useRef, useState } from "react";
import { MdEdit } from "react-icons/md";
import apiRequest from "../../lib/ApiRequest";
import toast from "react-hot-toast";
import ImageSlider from "../carousel/ImageSlider";
import RichTextInput from "../inputs/RichTextInput";
import ProfileImage from "./ProfileImage"


const ProfileHeader = ({ selectedTab, onTabChange }) => {
  const { data: user } = useQuery({ queryKey: ["user"] });
  const { data: authUser } = useQuery({ queryKey: ["authUser"] });
  const queryClient = useQueryClient();
  
    const [isEditingBio, setIsEditingBio] = useState(false); // State to control bio editing
  const [bio, setBio] = useState(user?.bio || ""); // User's bio state

  const displayName = user?.isSupplier ? (
    <>
      <span className="font-bold text-mainColor-500">
        {user?.company?.[0]?.companyName}
      </span>
    </>
  ) : (
    <>
      <span>{`${user?.firstName} ${user?.lastName}`}</span>
    </>
  );

  

  return (
    <div className="w-full mt-8 md:px-12 lg:px-16 xl:px-72 shadow-lg">
      {/* ImageSlider for Cover Images */}
      <ImageSlider slides={ user?.coverImg?.map((img) => ({ url: img })) || []}/>
      <div className="flex">
        
<ProfileImage />
        {/* Display user information */}
        <div className="flex flex-col items-start mt-16 px-6 relative top-[-50px]">
          <h1 className="text-5xl font-bold">{displayName}</h1>
          <p className="text-gray-600">
            {user?.followers?.length || 0} sledovatelia
          </p>
          <p className="text-gray-600">
            {user?.following?.length || 0} sledované
          </p>
        </div>
      </div>

 {/* Bio Section */}
 {/* <RichTextInput value={bio} onChange={setBio} /> */}
 {/* <div className="mt-4 w-full">
  {isEditingBio ? (
    <>

    <RichTextInput value={bio} onChange={setBio} />
    <button
  onClick={handleUpdateUser}
  className="bg-mainColor-500 text-white font-bold px-4 py-2 rounded mt-4"
  disabled={isUpdatingProfile}
>
  {isUpdatingProfile ? "Updating..." : "Uložiť zmenu"}
</button>
    </>
  ) : (
    // Display bio content with an edit option if the user matches authUser
    <div className="relative">
      <div className="prose prose-lg md:prose-xl lg:prose-2xl max-w-none text-gray-800">
      <div dangerouslySetInnerHTML={{ __html: sanitizedBio }} />
      </div>
      {authUser?._id === user?._id && (
        // Pencil icon to toggle RichTextInput editor
        <button
          onClick={() => setIsEditingBio(true)}
          className="absolute top-0 right-0 p-2 text-gray-500 hover:text-mainColor-500"
          aria-label="Edit Bio"
        >
          <MdEdit size={20} className="text-mainColor-500" />
        </button>
      )}
    </div>
  )}
</div> */}


      {/* Rest of your component */}
      <div className="">
        {/* Additional User Info */}
        <div className="flex flex-col ">
          <div className="gap-4 px-6 mt-6">
            <h2 className="text-xl text-mainColor-500 font-semibold">
              Kontakt
            </h2>
            <p className="text-gray-600">
              <strong>Zodpovedná osoba:</strong> {user?.firstName}{" "}
              {user?.lastName}
            </p>
            <p className="text-gray-600">
              <strong>Email:</strong> {user?.email}
            </p>
            <p className="text-gray-600">
              <strong>Telefón:</strong> {user?.phoneNumber}
            </p>
            <p className="text-gray-600">
              <strong>Web:</strong> Taprofi.sk
            </p>
          </div>

          <div className="gap-4 px-6">
            <p className="text-gray-600">
              <strong className="text-mainColor-500">Oblasť podnikania:</strong>{" "}
              {user?.company?.[0]?.businessArea}
            </p>
            <p className="text-gray-600">
              <strong className="text-mainColor-500">Oblasť pôsobenia:</strong>{" "}
              Stropkov, Svidník, Bardejov
            </p>
          </div>
        </div>
      </div>

      <div className="border-t mt-6">
        <div className="flex items-center justify-evenly gap-4 px-6 pt-4">
        <button
          className={`${
            selectedTab === "posts"
              ? "bg-mainColor-500 text-white"
              : "text-mainColor-500 border-mainColor-500"
          } font-bold px-4 py-2 rounded-t-xl border-2`}
          onClick={() => onTabChange("posts")}
        >
          Príspevky
        </button>
        <button
          className={`${
            selectedTab === "calendar"
              ? "bg-mainColor-500 text-white"
              : "text-mainColor-500 border-mainColor-500"
          } font-bold px-4 py-2 rounded-t-xl border-2`}
          onClick={() => onTabChange("calendar")}
        >
          Kalendár
        </button>
        <button
          className={`${
            selectedTab === "ratings"
              ? "bg-mainColor-500 text-white"
              : "text-mainColor-500 border-mainColor-500"
          } font-bold px-4 py-2 rounded-t-xl border-2`}
          onClick={() => onTabChange("ratings")}
        >
          Hodnotenia
        </button>
        </div>
      </div>
    </div>
  );
};

export default ProfileHeader;
