import { Formik } from "formik";
import * as yup from "yup";
import Input from "../../inputs/Input";
import Button from "../../Button";
import { useMutation } from "@tanstack/react-query";
import apiRequest from "../../../lib/ApiRequest";
import toast from "react-hot-toast";
import { useState } from "react";
import { TbMail } from "react-icons/tb";

const ResetPassword = () => {
  const initialValues = {
    email: "",
  };
  const [msg, setMsg] = useState();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [submittedEmail, setSubmittedEmail] = useState("");

  // React Query mutation to handle password reset
  const { mutate: resetPasswordMutation, isLoading } = useMutation({
    mutationFn: async ({ email }) => {
      try {
        const res = await apiRequest.post("auth/forgot-password", { email });
        if (res.status !== 200) {
          throw new Error("Failed to reset password. Please check your email.");
        }

        const data = res.data;
        if (!data || typeof data !== "object") {
          throw new Error("Invalid response data format.");
        }

        return data; // Return data for onSuccess
      } catch (error) {
        console.error("Error during password reset mutation:", error.message);
        throw error; // Let useMutation handle the error
      }
    },
    onSuccess: (data, variables) => {
      toast.success(
        "Odkaz na obnovenie hesla bol úspešne odoslaný na tvoj email."
      );
      setSubmittedEmail(variables.email);
      setIsSubmitted(true);
      setMsg(data.message)
    },
    onError: (error) => {
      const message = error.response?.data?.message || "Something went wrong!";
      setErrorMsg(message);
      toast.error(message || "An error occurred during password reset.");
    },
    retry: false,
  });

  // FORM FIELD VALIDATION SCHEMA
  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email("Invalid email format")
      .required("Please enter your email"),
  });

  // Handle form submission
  const handleSubmit = (values, { setSubmitting }) => {
    setSubmitting(true);
    resetPasswordMutation(values); // Call the mutation with form values
    setSubmitting(false);
  };

  return (
    <div>
      <h3 className="text-2xl font-semibold text-center mb-4">
        Zabudnuté heslo
      </h3>
      {!isSubmitted ? (
        <>
          <p className="text-center mb-6">
            Zadaj svoju e-mailovú adresu a pošleme ti odkaz na obnovenie hesla.
          </p>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit} // Use the handleSubmit function
          >
            {({
              handleSubmit,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="flex flex-col gap-4">
                  <Input name="email" label="Email" />
                  <Button
                    type="submit"
                    label={
                      isSubmitting || isLoading
                        ? "Loading..."
                        : "Resetovať Heslo"
                    }
                  />
                  {errorMsg && (
                    <p className="text-sm text-red-600">{errorMsg}</p>
                  )}
                  {msg && <p className="text-sm text-green-600">{msg}</p>}
                </div>
              </form>
            )}
          </Formik>
        </>
      ) : (
        <div className="text-center">
          <div className="w-16 h-16 bg-gray-300 rounded-full flex items-center justify-center mx-auto mb-4">
            <TbMail className="h-8 w-8 text-mainColor-500" />
          </div>
          <p className="text-gray-500 mb-6">
            Ak existuje účet pre{" "}
            <span className="text-mainColor-500">{submittedEmail}</span>,
            čoskoro obdržíš odkaz na obnovenie hesla.
          </p>
        </div>
      )}
    </div>
  );
};

export default ResetPassword;
